module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["GATRACK_ID"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-frontend/gatsby-browser.js'),
      options: {"plugins":[],"nodesConfig":[{"type":"node--page","endpoint":"node/page","slugTitleField":"title","hierarchyField":"field_parent_entity"},{"type":"node--article","endpoint":"node/article","slugTitleField":"title","hierarchyField":"field_parent_entity","dateFormat":"cccc, LLLL d t, y"},{"type":"node--fellow","endpoint":"node/fellow","skipPageCreation":true,"options":{"list":{"relationships":["field_fellow_award"]}}},{"type":"node--footer","endpoint":"node/footer"},{"type":"media--image","endpoint":"media/image"},{"type":"media--video","endpoint":"media/video"},{"type":"media--document","endpoint":"media/document"},{"type":"file--file","endpoint":"file/file"},{"type":"taxonomy_term--tags","endpoint":"taxonomy_term/tags"}],"themeConfig":{"googleFonts":"Hind:wght@400;600&family=Lora:ital@0;1","typography":{"fontFamily":"Hind,-apple-system,BlinkMacSystemFont,\"Segoe UI\",\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"","h1":{"fontFamily":"Lora,Georgia,Times New Roman,Times,serif"},"h2":{"fontFamily":"Lora,Georgia,Times New Roman,Times,serif"},"h3":{"fontFamily":"Lora,Georgia,Times New Roman,Times,serif"}},"palette":{"primary":{"main":"#89b3d2","light":"#97c0de","dark":"#6d9ec2","contrastText":"#ffffff"},"secondary":{"main":"#376299","light":"#4a79b5","dark":"#254773","contrastText":"#fff"},"amber":{"main":"#e6c281","light":"#dbc49a","dark":"#b89961","contrastText":"#fff"},"footer":{"main":"#565656","light":"#565656","dark":"#565656","contrastText":"#fafafa"},"appBar":{"private":{"main":"#fff","light":"#fff","dark":"#fff","contrastText":"#2c2c2c"},"public":{"main":"#f5f5f5","light":"#fafafa","dark":"#eeeeee","contrastText":"#424242"}},"titleBar":{"public":{"main":"#376299","light":"#376299","dark":"#376299","contrastText":"#fff"}},"body":{"main":"#f5f5f5","light":"#f5f5f5","dark":"#2c2c2c","contrastText":"#2c2c2c"}},"customOptions":{"navbarAlign":"right"}},"style":"/style.css","siteKey":"heed-site"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f3baa96d32ad477f65dc3940ce691ab8"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
